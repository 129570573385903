export const PROJECT_CONFIG = {
    defaultAvatar: '../../../../assets/images/default_user.png',
    defaultWomanAvatar: '../../../../assets/images/woman-avatar.jpg',
    defaultImage: '../../../../assets/images/default_image.png',
};

export const PROJECT_MESSAGES = {
    unknownError: 'Sorry there was an error try again later.',
    notAllFieldsAreFilled: 'Please, fill all required fields.',
    incorrectDataFormat: 'Incorrect data format.'
}

export const PROJECT_URL = 'https://gifts.dev.eboxenterprises.com';
// export const PROJECT_URL = 'https://gifts.eboxenterprises.com';
// export const PROJECT_URL = 'http://192.168.0.107:4202';

export const CASH_APP_PAYMENT_AMOUNT = 5;
